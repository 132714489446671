.input-box input[type="radio"],
.input-box input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.input-box {
    cursor: pointer;
    position: relative;
}
.input-box__label {
    line-height: 1.25;
    border-radius: calc(25rem / 16);
    padding: calc(9rem / 16) calc(12rem / 16) calc(6rem / 16);
    height: 100%;
    border: calc(1rem / 16) solid rgba(0,0,0,.2);
    transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding: calc(11rem / 16) calc(20rem / 16) calc(8rem / 16);
    }
}
.input-box input:checked ~ .input-box__label {
    color: #fff;
    background: var(--color-secondary);
    border-color: var(--color-secondary);
}

.input-box input:focus ~ .input-box__label,
.input-box__label:hover {
    border-color: var(--color-secondary);
}

/* colors */
.input-box--white .input-box__label {
    background: #fff;
    border-color: #fff;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.15);
}
.input-box--white input:focus ~ .input-box__label,
.input-box--white:hover .input-box__label {
    background: var(--color-secondary);
    border-color: var(--color-secondary);
    color: #fff;
}

/* radio row */
.input-box-row .input-box + .input-box {
    margin-left: calc(-1rem / 16);
}
.input-box-row .input-box + .input-box:not(:last-child) .input-box__label {
    border-radius: 0;
}
.input-box-row .input-box:last-child .input-box__label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-box-row .input-box:first-child .input-box__label {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

/* image */
.input-box-img input[type="radio"],
.input-box-img input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.input-box-img {
    cursor: pointer;
    position: relative;
}
.input-box-img__content {
    opacity: .5;
    transition: opacity 200ms ease-in-out;
    font-size: calc(13rem / 16);
    letter-spacing: calc(2rem / 16);
}
.input-box-img:hover .input-box-img__content,
.input-box-img__input:checked ~ .input-box-img__content {
    opacity: 1;
}
.input-box-img__state {
    height: calc(40rem / 16);
    width: calc(40rem / 16);
    background-color: #fff;
    box-shadow: 0 calc(4rem / 16) calc(5rem / 16)5px 0 rgba(50,50,50,0.2);
    border-radius: 50%;
    position: absolute;
    top: calc(-10rem / 16);
    right: calc(-10rem / 16);
    transition: background 200ms ease-in-out;
    z-index: 1;
}
.input-box-img__state:before,
.input-box-img__state:after {
    content: var(--icon-checkmark);
    font-family: iconfont;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(14rem / 16);
    opacity: 0;
}
.input-box-img__state:before {
    content: '';
    width: calc(15rem / 16);
    height: calc(15rem / 16);
    border: calc(2rem / 16) solid var(--color-secondary);
    border-radius: calc(3rem / 16);
    opacity: .4;
}
.input-box-img__input:checked ~ .input-box-img__content .input-box-img__state {
    background: var(--color-secondary);
    color: #fff;
}
.input-box-img__input:checked ~ .input-box-img__content .input-box-img__state:after {
    opacity: 1;
}
.input-box-img__input:checked ~ .input-box-img__content .input-box-img__state:before {
    opacity: 0;
}