.floating-label {
    position: absolute;
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 4;
    line-height: calc(34/18);
    text-transform: uppercase;
    font-size: calc(12rem / 16);
    letter-spacing: calc(2rem / 16);

    left: calc(25rem / 16);
    top: calc(15rem / 16);
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label{
    transform: translateY(calc(-10rem / 16)) scale(.75);
}
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(calc(-10rem / 16)) scale(.75);
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(calc(-10rem / 16)) scale(.75);
}

/* sizes */
.floating-label--sm {
    top: calc(12rem / 16);
}
select ~ .floating-label--sm,
.form-group.has-value .floating-label--sm {
    transform: translateY(calc(-8rem / 16)) scale(.75);
}