.quote-box {
    background: #fff;
}
.quote-box__content {
    padding: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) 15% calc(50rem / 16) 20%;
    }
}
.quote-box__content__text {
    position: relative;
    font-size: calc(15rem / 16);
    line-height: calc(28/18);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.quote-box__content__text:before {
    content: var(--icon-quote);
    font-family: iconfont;
    line-height: 1;
    position: absolute;
    top: calc(-10rem / 16);
    left: calc(-10rem / 16);
    font-size: calc(60rem / 16);
    color: var(--color-primary);
    opacity: .2;

    @media screen and (min-width: 768px) {
        top: calc(-40rem / 16);
        left: calc(-55rem / 16);
        font-size: calc(155rem / 16);
    }
}

.quote-box__content__position {
    font-size: calc(13rem / 16);
    letter-spacing: calc(2rem / 16);
}
.quote-box__content__author {
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
    }
}

.quote-box__content__additional {
    position: relative;
    padding-top: calc(15rem / 16);
    margin-top: calc(8rem / 16);
}
.quote-box__content__additional:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(62rem / 16);
    height: calc(2rem / 16);
    background: var(--color-default);
    opacity: .12;
}