@font-face {
    font-family: 'Overpass-ExtraBold';
    src: url('/static/fonts/Overpass-ExtraBold.woff2') format('woff2'),
    url('/static/fonts/Overpass-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Overpass-Light';
    src: url('/static/fonts/Overpass-Light.woff2') format('woff2'),
    url('/static/fonts/Overpass-Light.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'lg';
    src: url('/static/fonts/lg.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}