.slide {
    margin-top: calc(40rem/16);
    margin-bottom: calc(40rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(90rem/16);
        margin-bottom: calc(90rem/16);
    }
}
.slide-sm {
    margin-top: calc(30rem / 16);
    margin-bottom: calc(30rem / 16);
}

/* slides with background color / image */
.slide.decorated-bg {
    margin-top: 0;
    margin-bottom: 0;
}
.slide[class*='bg-']:not(.decorated-bg),
.slide--img-bg {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: calc(30rem / 16);
    padding-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: calc(90rem / 16);
        padding-bottom: calc(90rem / 16);
    }
}
.slide-sm[class*='bg-'],
.slide-sm.slide--shadow-gradient {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: calc(30rem / 16);
    padding-bottom: calc(30rem / 16);
}

.slide--shadow-gradient {
    background: linear-gradient(180deg, var(--color-page-bg) 0%, #FFFFFF 100%);
    box-shadow: 0 calc(20rem / 16) calc(20rem / 16) 0 rgba(15,15,15, 0.03);
}
.slide--img-bg {
    background-size: cover;
}