.map__info-box {
    background: #fff;
}
.map__info-box > img {
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 2;
    padding: calc(6rem / 16);
}
.map__info-box .teaser {
    z-index: 1;
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}
.map__info-box .teaser__title {
    @media screen and (max-width: 767px) {
        font-size: calc(18rem / 16);
    }
}