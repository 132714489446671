.fact-box {
    font-size: calc(13rem / 16);
    letter-spacing: calc(2rem / 16);
    position: relative;
    padding: calc(20rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(60rem / 16) calc(50rem / 16);
    }
}
.fact-box:not(:first-child):before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: calc(1rem / 16);
    background: var(--color-secondary);
    opacity: .12;

    @media screen and (min-width: 768px) {
        bottom: 0;
        right: auto;
        height: auto;
        width: calc(1rem / 16);
    }
}
.fact-box__value {
    font-size: calc(24rem / 16);
    letter-spacing: normal;

    @media screen and (min-width: 768px) {
        font-size: calc(35rem / 16);
    }
}
.fact-box__label {
    font-size: calc(15rem / 16);
}