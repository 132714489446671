.lightbox-item:after {
    content: var(--icon-expand);
    font-family: iconfont;
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    border-radius: 50%;
    background: #fff;
    color: var(--color-secondary-light);
    box-shadow: 0 0 calc(40rem / 16) 0 rgba(40,43,52,0.2);
    font-size: calc(22rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

@media screen and (max-width: 767px) {
    .lightbox-item--disable-xs {
        pointer-events: none;
    }
    .lightbox-item--disable-xs:after {
        display: none;
    }
}