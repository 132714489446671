.asset-video{
    cursor: pointer;
}

.asset-video__play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    width: calc(40rem/16);
    height: calc(40rem/16);
    background-color: #fff;
    box-shadow: 0 0 calc(40rem / 16) 0 rgba(40,43,52,0.2);
    color: var(--color-secondary);
    font-size: calc(13rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    transition: all .25s ease;
}

.is-playing .asset-video__play{
    display: none;
}