.meta-item {
    padding: 0 calc(5rem / 16);
    flex: 1 1 auto;
    text-align: center;
}
.meta-item:not(:first-child):before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(1rem / 16);
    background: rgba(0,0,0,.1);
}
.meta-item__label {
    font-size: calc(12rem / 16);
    letter-spacing: calc(2rem / 16);
    line-height: calc(18/12);
}
.meta-item--auto {
    flex: 0 0 auto;
}
.meta-item--auto:first-child {
    text-align: left;
}
.meta-item--auto + .meta-item--auto {
    margin-left: calc(20rem / 16);
    padding-left: calc(20rem / 16);

    @media screen and (min-height: 768px) {
        margin-left: calc(35rem / 16);
        padding-left: calc(35rem / 16);
    }
}

@media screen and (max-width: 767px) {
    .meta-item--50-xs {
        flex: 1 1 50%;
    }
    .meta-item.meta-item--50-xs:before {
        content: none;
    }

}