.icon-in-text {
    vertical-align: -.15em;
}
.icon-in-text--md {
    font-size: calc(20rem / 16);
}
.icon-in-text--lg {
    font-size: calc(22rem / 16);
    vertical-align: -.2em;
}
.icon-in-text--sm {
    font-size: .8em;
}

/* Icons in text fixed width */
.icon-in-text--fixed {
    text-align: center;
    font-size: calc(20rem / 16);
    width: calc(22rem / 16);
    margin-right: calc(10rem / 16);
    vertical-align: -.2em;
}
.icon-in-text--fixed-lg {
    font-size: calc(20rem / 16);
    width: calc(22rem / 16);
    margin-right: calc(5rem / 16);
    margin-top: calc(-3rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(10rem / 16);
        font-size: calc(30rem / 16);
        width: calc(30rem / 16);
        margin-top: calc(-8rem / 16);
    }
}