.progress-svg {
    filter: drop-shadow(0 0 calc(3rem / 16) rgba(0,0,0,.1));
}
.progress-svg__active,
.progress-svg__inactive {
    cursor: pointer;
    transition: opacity 200ms ease-in-out;
}

.is-active .progress-svg__active {
    opacity: 1;
}
.is-active .progress-svg__inactive,
.progress-svg__active {
    opacity: 0;
}


.progress-svg-list__item {
    transition: opacity 200ms ease-in-out;
}
.progress-svg-list__item.is-inactive,
.progress-svg-list:hover .progress-svg-list__item {
    opacity: .5;
}
.progress-svg-list .progress-svg-list__item:hover {
    opacity: 1;
}