.img-text-box__content {
    padding: calc(30rem / 16);
    background: #fff;
    box-shadow: 0 calc(15rem / 16) calc(70rem / 16) 0 rgba(40,43,52,0.1);
    font-size: calc(14rem / 16);

    @media screen and (min-width: 1400px) {
        padding: calc(100rem / 16);
        font-size: calc(16rem / 16);
        line-height: calc(25/16);
    }
}

.img-text-box__title {
    z-index: 1;
}
.img-text-box__title[data-backgound-title]:before {
    content: attr(data-backgound-title);
    position: absolute;
    left: calc(-10rem / 16);
    top: calc(-10rem / 16);
    line-height: 1;
    width: 100%;
    font-size: calc(55rem / 16);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    opacity: .15;
    z-index: -1;

    @media screen and (min-width: 768px) {
        font-size: calc(80rem / 16);
        left: calc(-30rem / 16);
        top: calc(-40rem / 16);
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(130rem / 16);
        top: calc(-40rem / 16);
        width: 150%;
    }
}
.img-text-box--content-left .img-text-box__title[data-backgound-title]:before {
    @media screen and (min-width: 1400px) {
        left: calc(-150rem / 16);
    }
}

/* sizes */
.img-text-box--sm .img-text-box__content {
    @media screen and (min-width: 768px) {
        padding: calc(60rem / 16) calc(100rem / 16);
    }
}
.img-text-box--sm .img-text-box__title {
    font-size: calc(25rem / 16);
}