@media (hover: none) {
    .simple-nav__item.has-children > .simple-nav__item-content--link {
        display: none;
    }
    .simple-nav__item.has-no-children > .simple-nav__item-content--toggle {
        display: none;
    }
}
@media screen and (max-width: 767px) {
    .simple-nav__item.has-children > .simple-nav__item-content--link {
        display: none;
    }
    .simple-nav__item.has-no-children > .simple-nav__item-content--toggle {
        display: none;
    }
}

.simple-nav__item-content--toggle {
    display: none;
}

.simple-nav {
    position: relative;
    padding: 0 var(--simple-nav-level-0-item-spacing);

    @media screen and (min-width: 1400px) {
        padding: 0 calc(25rem / 16);
    }
}
.simple-nav__logo {
    max-width: calc(120rem / 16);
    height: calc(60rem / 16);
    padding: calc(10rem / 16);
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
        max-width: calc(140rem / 16);
    }
    @media screen and (min-width: 1400px) {
        padding: calc(25rem / 16) 0 0 calc(30rem / 16);
        max-width: calc(250rem / 16);
    }
}
.simple-nav__logo__img {
    max-height: 100%;
    width: auto;
}
.simple-nav__btn {
    line-height: calc(22/14);
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    font-size: calc(9rem / 16);
    letter-spacing: calc(.3rem / 16);

    @media screen and (min-width: 1200px) {
        width: auto;
        height: auto;
        font-size: calc(12rem / 16);
        letter-spacing: normal;
        padding: calc(10rem / 16) calc(18rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(14rem / 16);
        padding: calc(10rem / 16) calc(24rem / 16);
        letter-spacing: calc(.9rem / 16);
    }
}
.simple-nav__btn__icon {
    font-size: calc(25rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(35rem / 16);
    }
}
.simple-nav__list--level-0 {
    @media screen and (min-width: 1200px) {
        background: #fff;
        box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
    }
}

.simple-nav-container {
    z-index: 10;
    position: relative;
    transition: background 200ms ease-in-out, box-shadow 200ms ease-in-out;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(174.16deg, #FFFEF7 0%, rgba(255,254,247,0) 95%);
}
.simple-nav-container.is-affix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
}
.is-affix .simple-nav__list--level-0 {
    box-shadow: none;
}
.is-affix .simple-nav__logo {
    @media screen and (min-width: 1200px) {
        width: auto;
        max-height: calc(68rem / 16);
        padding: calc(20rem / 16) 0 0 calc(14rem / 16);
    }
    @media screen and (min-width: 1400px) {
        padding: calc(15rem / 16) 0 0 calc(30rem / 16);
    }
}