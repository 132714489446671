.decorated-link {
    position: relative;
    font-size: calc(13rem / 16);
    line-height: calc(20/13);
    letter-spacing: calc(2rem / 16);
    padding-bottom: calc(5rem / 16);
    z-index: 1;
}
.decorated-link:before {
    content: '';
    position: absolute;
    left: calc(-2rem / 16);
    right: calc(2rem / 16);
    bottom: calc(1rem / 16);
    background: var(--color-primary);
    height: calc(3rem / 16);
    /*transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;*/
    transform-origin: bottom center;
    z-index: -1;
}
/*.decorated-link:hover:before {
    transform: scaleY(3.8);
    opacity: .8;
}*/
.decorated-link--blue:before {
    background: #fff;
}

.decorated-link--xl {
    font-size: calc(14rem / 16);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
        letter-spacing: calc(4rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(30rem / 16);
        letter-spacing: calc(7.5rem / 16);
    }
}