.data-item {
    font-size: calc(13rem / 16);
    line-height: calc(19/15);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
    }
}
.data-item__icon {
    margin-top: calc(2rem / 16);
    font-size: calc(28rem / 16);
    width: calc(30rem / 16);
    margin-right: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}
.data-item__value {
    font-size: calc(18rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}