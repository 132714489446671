:root {
    --simple-nav-level-0-item-spacing: calc(12rem / 16);
}

@media (hover: none) {
    .simple-nav__item-content--level-0 {
        display: flex;
    }
}
@media screen and (max-width: 1199px) {
    .simple-nav__item-content--level-0 {
        display: flex;
    }
}

.simple-nav--level-0 .simple-nav__item-content--level-0 {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    letter-spacing: calc(.47rem / 16);
    text-transform: uppercase;
    font-size: calc(14rem / 16);

    @media screen and (min-width: 1200px) {
        flex-direction: column;
        flex: auto;
        padding: calc(36rem/16) .85rem;
        font-size: calc(10rem / 16);
        transition: background 200ms ease-in-out, color 200ms ease-in-out;
    }

    @media screen and (min-width: 1680px) {
        font-size: calc(14rem / 16);
        padding: calc(36rem / 16) calc(25rem / 16);
    }
}

.is-affix.is-affix .simple-nav__item-content--level-0 {
    @media screen and (min-width: 1200px) {
        padding-top: calc(24rem / 16);
        padding-bottom: calc(24rem / 16);
    }
}

.simple-nav__list--level-0 {
    @media screen and (min-width: 1200px) {
        display: flex;
        flex-direction: row;
        margin-left: calc(var(--simple-nav-level-0-item-spacing) * -1);
        margin-right: calc(var(--simple-nav-level-0-item-spacing) * -1);
    }

    @media screen and (min-width: 1400px) {
        margin-left: calc(calc(25rem / 16) * -1);
        margin-right: calc(calc(25rem / 16) * -1);
    }
}

@media screen and (min-width: 1200px) {
    .simple-nav--level-0 {
        display: flex;
        flex: auto;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
    }

    .simple-nav__item--level-0 {
        display: flex;
        flex-direction: column;
        flex: auto;
        position: relative;
    }

    .simple-nav__item.is-active > .simple-nav__item-content--level-0 {
        background: var(--color-secondary);
        color: var(--color-secondary-contrast);
    }
    .simple-nav__item.is-active:before {
        content: '';
        top: 100%;
        left: 0;
        right: 0;
        position: absolute;
        padding-top: calc(10rem / 16);
        background: var(--color-secondary);
        clip-path: polygon(0 0, 50% 100%, 100% 0);
    }

    .simple-nav__item:hover .simple-nav__item-content--level-0,
    .simple-nav__item-content--level-0:focus,
    .simple-nav__item-content--level-0:active,
    .simple-nav__item.is-open > .simple-nav__item-content {
        background: var(--color-secondary);
        color: var(--color-secondary-contrast);
    }
}