.close.cookie-modal__close {
    position: absolute;
    top: calc(-15rem / 16);
    right: calc(-15rem / 16);
    border-radius: 50%;
    background: var(--color-secondary);
    color: #fff;
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    border: 0;
    opacity: 1;
    margin: 0;
    font-size: calc(12rem / 16);
    transition: background 200ms ease-in-out;
    z-index: 1;

    @media screen and (min-width: 768px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
        top: calc(-20rem / 16);
        right: calc(-20rem / 16);
    }
}
.modal .close.cookie-modal__close:hover {
    opacity: 1;
    background: var(--color-secondary-dark);
}
.cookie-modal__close:before {
    content: var(--icon-close);
    font-family: iconfont;
}

.cookie-accept__btn {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 1;
}