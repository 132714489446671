.simple-nav.simple-nav--level-1 {
    @media screen and (min-width: 1200px) {
        display: block;
        background-color: var(--color-secondary);
        color: var(--color-secondary-contrast);
        z-index: 10;
        position: absolute;
        top: 100%;
        left: 0;
        visibility: hidden;
        opacity: 0;
        padding: 0 calc(9rem / 16) calc(25rem / 16);
        min-width: 100%;
        transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
    }

    @media screen and (min-width: 1600px) {
        padding: 0 calc(25rem / 16) calc(25rem / 16);
    }
}
@media screen and (min-width: 1200px) {
    .simple-nav--level-1:before {
        content: '';
        top: 100%;
        left: 0;
        right: 0;
        position: absolute;
        padding-top: calc(12rem / 16);
        background: var(--color-secondary);
        clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
    .simple-nav__list--level-1 {
        margin-top: calc(-10rem / 16);
        transition: transform 200ms;
        transform: translateY(calc(-10rem / 16));
    }
    .simple-nav__item.is-open .simple-nav__list--level-1,
    .simple-nav__item:hover .simple-nav__list--level-1 {
        transform: translateY(0);
    }
    .simple-nav__item:hover > .simple-nav--level-1,
    .simple-nav__item.is-open > .simple-nav--level-1 {
        opacity: 1;
        visibility: visible;
    }
    .simple-nav__item--level-1 + .simple-nav__item--level-1 {
        margin-top: calc(13rem / 16);
    }

    .simple-nav__item-content--level-1 {
        padding: calc(3rem / 16) 0;
        display: block;
        font-size: calc(12rem / 16);
        line-height: calc(20/16);
    }
    .simple-nav__item-content--level-1:hover,
    .simple-nav__item-content--level-1:focus,
    .simple-nav__item-content--level-1:active {
        color: var(--color-primary);
    }
}

@media screen and (min-width: 1600px) {
    .simple-nav__item-content--level-1 {
        font-size: calc(15rem / 16);
    }
}