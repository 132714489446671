.teaser {
    background: #fff;
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
    position: relative;
}
.teaser__img {
    transition: transform 200ms ease-in-out;
}
.teaser:hover .teaser__img {
    transform: scale(1.05);
}
.teaser__badge {
    position: absolute;
    left: calc(-20rem / 16);
    top: calc(20rem / 16);
    max-width: 70%;
    white-space: normal;
}
.teaser__body {
    padding: calc(15rem / 16) calc(20rem / 16);
}
.teaser__title {
    font-size: calc(18rem / 16);
    line-height: calc(24/18);
}