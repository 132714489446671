.quickfilter {
    font-size: calc(14rem / 16);
}
.quickfilter-btn {
    font-size: calc(14rem / 16);
    padding: calc(4rem / 16) calc(12rem / 16) calc(3rem / 16) calc(34rem / 16);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    text-transform: none;
    letter-spacing: normal;
    position: relative;
}
.quickfilter-btn__icon {
    pointer-events: none;
    width: calc(22rem / 16);
    height: calc(22rem / 16);
    background: #fff;
    color: var(--color-dark);
    border-radius: 50%;
    font-size: calc(7rem / 16);
    position: absolute;
    left: calc(3rem / 16);
    top: 50%;
    transform: translateY(-50%);
}