.img-slider {
    @media screen and (min-width: 768px) {
        padding: 0 15%;
    }
    @media screen and (min-width: 1400px) {
        padding: 0 21%;
    }
}
.img-slider__item {
    scroll-snap-align: center;

    @media screen and (min-width: 768px) {
        max-width: calc(1107rem / 16);
        margin: 0 calc(-50rem / 16);
    }
}
.img-slider__item:first-child {
    margin-left: 0;
}
.img-slider__item__img {
    @media screen and (min-width: 768px) {
        transition: transform 200ms ease-in-out;
        transform: scale(0.6);
    }
}
.is-active .img-slider__item__img{
    @media screen and (min-width: 768px) {
        transform: scale(1);
    }
}