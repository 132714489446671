/* colors */
.btn-white {
    background: #fff;
    color: var(--color-secondary-light);
}
.btn-light-grey {
    background: var(--color-light-grey);
    color: var(--color-dark);
}
.btn-light-grey:hover,
.btn-light-grey:focus {
    background: #d5d5d5;
    color: var(--color-dark);
}
.btn__icon {
    font-size: calc(20rem / 16);
    vertical-align: -0.25em;
    line-height: 0;
}

.btn-shadow {
    box-shadow: 0 15px 50px 0 rgba(40,43,52,0.1);
}

.collapsed .collapse-btn__show {
    display: block;
}
.collapse-btn__show,
.collapsed .collapse-btn__hide {
    display: none;
}