.job-teaser {
    position: relative;
    padding: calc(48rem / 16) calc(16rem / 16) calc(8rem / 16) calc(16rem / 16);
    background-color: var(--color-white);
    box-shadow: 0 0.9375rem 3.125rem 0 rgb(40 43 52 / 10%);

    @media screen and (min-width: 768px) {
        padding: calc(48rem / 16) calc(32rem / 16) calc(16rem / 16) calc(32rem / 16);
    }
}

.job-teaser__headline {
    font-size: calc(18rem / 16);
    line-height: calc(20rem / 16);
    
    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
        line-height: calc(30rem / 16);
    }
}

.job-teaser:hover .job-teaser__badge {
    transform: rotate(-4deg);
}

.job-teaser__badge {
    position: absolute;
    top: calc(4rem / 16);
    left: calc(-20rem / 16);
    display: flex;
    align-items: center;
    padding: calc(6rem / 16) calc(7rem / 16);
    transition: all 0.2s ease-in-out;

    @media screen and (min-width: 768px) {
        top: calc(8rem / 16);
        padding: calc(8rem / 16) calc(11rem / 16);
    }
}

.job-teaser__badge-text {
    margin-left: calc(4rem / 16);
    line-height: 1;
}