.img-grid {
    position: relative;
}
.img-grid:before {
    content: '';
    position: absolute;
    left: calc(-30rem / 16);
    top: 20%;
    bottom: 20%;
    width: 90%;
    background: var(--color-primary);
    opacity: .2;
    z-index: -1;

    @media screen and (min-width: 768px) {
        left: 0;
    }
}