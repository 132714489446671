.horizontal-teaser {
    background: #fff;
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
    position: relative;
    padding: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(40rem / 16);
    }
}
.horizontal-teaser + .horizontal-teaser {
    margin-top: calc(20rem / 16);
}
.horizontal-teaser__title {
    font-size: calc(18rem / 16);
    line-height: calc(24/18);
}