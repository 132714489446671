@media screen and (max-width: 767px) {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        max-height: 100%;
        z-index: 11;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        margin: 0;
        background: #fff;
    }
    .is-open .search-bar__overlay,
    .is-open.overlay {
        transform: translateX(0);
    }

    .overlay__head {
        background: #fff;
        font-size: calc(12rem / 16);
        letter-spacing: calc(2rem / 16);
        color: var(--color-secondary-light);
        box-shadow: 0 calc(5rem / 16) calc(15rem / 16) 0 rgba(15,15,15,0.1);
    }
    .overlay__head__close {
        background: var(--color-secondary);
        color: #fff;
        border: 0;
        width: calc(60rem / 16);
        height: calc(60rem / 16);
        font-size: calc(9rem / 16);
        letter-spacing: calc(.3rem / 16);
    }
    .overlay__head__close__icon {
        font-size: calc(18rem / 16);
    }
    .overlay__container {
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }
    .overlay__content {
        padding: calc(30rem / 16) 0;
        height: calc(100vh - calc(180rem / 16));
        max-height: calc(100% - calc(120rem / 16));
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: calc(60rem / 16);
    }
    .overlay__content--full {
        height: calc(100vh - calc(120rem / 16));
    }
    .overlay__submit {
        height: calc(60rem / 16);
        z-index: 200;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0 calc(7rem / 16) calc(30rem / 16) 0 rgba(97,74,0,0.2);
    }


    .collapse.collapse--overlay-xs {
        display: block;
    }
}