.scrolling-area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(8rem / 16);

    scroll-snap-type: x mandatory;
}
/*.container .scrolling-area {
    margin-left: calc(-30rem / 16);
    margin-right: calc(-30rem / 16);
    padding-left: calc(30rem / 16);
    padding-right: calc(30rem / 16);
}*/
.scrolling-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);
    background-color: transparent;
    transition: all 120ms ease;
}

.scrolling-area--xs::-webkit-scrollbar,
.scrolling-area::-webkit-scrollbar {
    width: calc(8rem / 16);
    height: calc(8rem / 16);
    background: var(--color-light-grey);
    transition: all 120ms ease;
}

.scrolling-area--xs::-webkit-scrollbar-thumb,
.scrolling-area::-webkit-scrollbar-thumb {
    background-color: var(--color-secondary);
    transition: all 120ms ease;
}

@media screen and (max-width: 767px) {
    .scrolling-area--xs {
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .container .scrolling-area--xs {
        margin-left: calc(-30rem / 16);
        margin-right: calc(-30rem / 16);
        padding-left: calc(30rem / 16);
        padding-right: calc(30rem / 16);
    }
    .scrolling-area--xs.row--offset {
        padding-top: calc(50rem / 16);
        margin-top: calc(-50rem / 16);
    }
}


.scrolling-area--slider {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: 0;
}
.scrolling-area--slider::-webkit-scrollbar {
    display: none;
}
.scrolling-area--slider {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    position: relative;
}
.scrolling-area__item {
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: start;
}
.scrolling-area__arrow.scrolling-area__arrow {
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    font-size: calc(10rem / 16);
    background: var(--color-light-grey);
    color: var(--color-secondary-light);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(5rem / 16);
    z-index: 1;
}
.scrolling-area__arrow.scrolling-area__arrow--prev {
    transform: translateX(-100%);
    margin-right: calc(2rem / 16);
}
.scrolling-area__arrow[disabled] {
    opacity: .7;
}

.scrolling-area--shadow {
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
}