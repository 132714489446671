.decorated-bg {
    position: relative;
    padding-top: calc(40rem / 16);
    padding-bottom: calc(40rem / 16);
}
.decorated-bg:before {
    content: '';
    position: absolute;
    left: calc(-30rem / 16);
    top: 0;
    bottom: 0;
    width: 60vw;
    background: var(--color-primary);
    opacity: .2;
    z-index: -1;
}
.decorated-bg--right:before {
    left: auto;
    right: 0;
}

.decorated-bg--offset-right {
    padding-bottom: 0;
}
.decorated-bg--offset-right:before {
    left: auto;
    right: calc(-30rem / 16);
    width: 70%;
    top: calc(-20rem / 16);
    height: 80%;

    @media screen and (min-width: 768px) {
        top: calc(-20rem / 16);
    }
}
.decorated-bg--bottom:before {
    left: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    height: 50%;
}
.decorated-bg.decorated-bg--center:before {
    top: 50%;
    transform: translateY(-50%);
    height: 60%;
}

.decorated-bg--fill {
    padding: calc(30rem / 16) calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) calc(25rem / 16);
    }
}
.decorated-bg--fill:before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
}

.decorated-bg--squares:after,
.decorated-bg--squares:before {
    content: '';
    bottom: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 35%;
    height: 63%;
    background: var(--color-primary);
    opacity: .2;
    z-index: -1;

    @media screen and (min-width: 1400px) {
        right: calc(-50rem / 16);
    }
}
.decorated-bg--squares:before {
    right: auto;
    top: auto;
    bottom: 0;
    left: 0;

    @media screen and (min-width: 1400px) {
        right: auto;
        left: calc(-50rem / 16);
    }
}