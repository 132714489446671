.info-row {
    padding: calc(20rem / 16) calc(10rem / 16);
    transition: box-shadow 200ms ease-in-out;
    border-top: calc(1rem / 16) solid var(--color-light-grey);
}
.info-row:last-child {
    border-bottom: calc(1rem / 16) solid var(--color-light-grey);
}
.info-row__title {
    letter-spacing: 0;
}
.info-row:hover {
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
}