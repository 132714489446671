.nav-tabs .nav-link {
    border: 0;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    text-transform: uppercase;
    letter-spacing: calc(2rem / 16);
    white-space: nowrap;
}
.nav-tabs--default {
    background: #fff;
    color: var(--color-secondary-light);
    display: flex;
    align-items: center;
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
    padding: calc(5rem / 16);
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        border-radius: calc(40rem / 16);
    }
}
@media screen and (max-width: 767px) {
    .container:not(.container--no-padding-xs) .nav-tabs--default {
        margin-left: calc(-30rem / 16);
        margin-right: calc(-30rem / 16);
    }
}
.nav-tabs--default .nav-link {
    padding: calc(8rem / 16) calc(20rem / 16);
    font-size: calc(13rem / 16);
    line-height: calc(20/13);
    border-radius: calc(40rem / 16);
    transition: background 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16) calc(40rem / 16);
    }
}
.nav-tabs--default .active.nav-link {
    background: var(--color-primary);
    color: var(--color-secondary);
}

@media screen and (min-width: 768px) {
    .nav-tabs--overlap {
        position: relative;
        transform: translateY(50%);
        z-index: 1;
    }
}

.tab-content--shadow {
    box-shadow: 0 calc(15rem / 16) calc(70rem / 16) 0 rgba(40,43,52,0.1);
}
.tab-pane {
    position: relative;
    padding: calc(10rem / 16) calc(30rem / 16);
}
.tab-pane__bg-icon {
    position: absolute;
    bottom: 0;
    right: calc(20rem / 16);
    font-size: calc(180rem / 16);
    opacity: .15;
    line-height: 1;
    z-index: -1;

    @media screen and (min-width: 768px) {
        right: calc(45rem / 16);
        font-size: calc(384rem / 16);
    }
}