/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Text Color Helper */
.text-white { color: #ffffff; }
.text-secondary { color: var(--color-secondary) }
.text-color-default { color: var(--color-text-default); }
.text-secondary-light {color: var(--color-secondary-light)}
.text-grey {color: var(--color-grey);}
/* Background Color Helper */
.bg-light-grey {background: var(--color-light-grey)}
.bg-primary-light {
    background: rgba(243, 223, 94, 0.2);
}


/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}
.clickable {cursor: pointer;}
.label-text {
    color: var(--color-dark-grey);
    font-size: calc(11rem / 16);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        letter-spacing: calc(1.8rem / 16);
        font-size: calc(12rem / 16);
    }
}
.backlink {
    font-size: calc(13rem / 16);
    letter-spacing: calc(2rem / 16);
}
.backlink__icon {
    font-size: calc(23rem / 16);
    margin-right: calc(8rem / 16);
}

.email-link:after {
    content: attr(data-name) "@" attr(data-domain);
}