.form-control {
    padding: calc(20rem / 16) calc(24rem / 16) calc(8rem / 16);
    height: calc(50rem / 16);
    border-radius: calc(25rem / 16);
}
/* sizes */
.form-control-sm {
    padding: calc(18rem / 16) calc(24rem / 16) calc(4rem / 16);
    height: calc(42rem / 16);
}

/* colors */
.form-control--light,
.form-control--light:focus {
    background: transparent;
}

.form-control--white,
.form-control--white:focus {
    border-color: #fff;
    background: #fff;
}
.form-control--shadow:focus,
.form-control--shadow {
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.15);
}

/* input group */
.input-group-append__btn {
    padding: calc(5rem / 16) calc(12rem / 16);
    font-size: calc(18rem / 16);
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-input .btn,
.file-input .form-control {
    padding: calc(14rem / 16) calc(20rem / 16);
}