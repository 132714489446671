/* rem gutters */
.row-gutter--0 {
    margin-left: 0;
    margin-right: 0;
}
.row-gutter--0 > [class^="col"],
.row-gutter--0 > [class*=" col"],
.row-gutter--0.slick-slider [class^="col"],
.row-gutter--0.slick-slider [class*=" col"] {
    padding-left: 0;
    padding-right: 0;
}
.row-gutter--1 {
    margin-left: -.125rem;
    margin-right: -.125rem;
}
.row-gutter--1 > [class^="col"],
.row-gutter--1 > [class*=" col"],
.row-gutter--1.slick-slider [class^="col"],
.row-gutter--1.slick-slider [class*=" col"] {
    padding-left: .125rem;
    padding-right: .125rem;
}
.row-gutter--2 {
    margin-left: -.25rem;
    margin-right: -.25rem;
}
.row-gutter--2 > [class^="col"],
.row-gutter--2 > [class*=" col"],
.row-gutter--2.slick-slider [class^="col"],
.row-gutter--2.slick-slider [class*=" col"] {
    padding-left: .25rem;
    padding-right: .25rem;
}
.row-gutter--3 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}
.row-gutter--3 > [class^="col"],
.row-gutter--3 > [class*=" col"],
.row-gutter--3.slick-slider [class^="col"],
.row-gutter--3.slick-slider [class*=" col"] {
    padding-left: .5rem;
    padding-right: .5rem;
}
.row-gutter--4 {
    margin-left: -.75rem;
    margin-right: -.75rem;
}
.row-gutter--4 > [class^="col"],
.row-gutter--4 > [class*=" col"],
.row-gutter--4.slick-slider [class^="col"],
.row-gutter--4.slick-slider [class*=" col"] {
    padding-left: .75rem;
    padding-right: .75rem;
}

/*use a media query for bigger row gutters; otherwise the page is scrollable on mobile devices */
@media screen and (min-width: 768px) {
    .row-gutter--5 {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .row-gutter--5 > [class^="col"],
    .row-gutter--5 > [class*=" col"],
    .row-gutter--5.slick-slider [class^="col"],
    .row-gutter--5.slick-slider [class*=" col"] {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.row--offset {
    margin-top: calc(50rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-50rem / 16);
        margin-top: calc(60rem / 16);
    }
}
.row--offset__item {
    @media screen and (min-width: 768px) {
        margin-bottom: calc(50rem / 16);
    }
}
.row--offset__item:nth-child(even) {
    margin-top: calc(-40rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(-80rem / 16);
    }
}

.row--offset--md {
    margin-top: calc(-30rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-50rem / 16);
        margin-top: calc(60rem / 16);
    }
}
.row--offset--md .row--offset__item {
    margin-top: calc(30rem / 16);
}
.row--offset--md .row--offset__item:nth-child(even) {
    @media screen and (min-width: 768px) {
        margin-top: calc(-80rem / 16);
    }
}