.filter-dropdown {
    position: relative;
}
.is-open.filter-dropdown {
    @media screen and (max-width: 767px) {
        background: var(--color-light-grey);
        border-radius: calc(25rem / 16);
        overflow: hidden;
    }
}
.filter-dropdown__btn {
    white-space: nowrap;
    text-align: left;
    position: relative;
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    padding-right: calc(35rem / 16);
    background: var(--color-light-grey);
    color: var(--color-dark);
}
.filter-dropdown__btn.is-selected {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
.filter-dropdown__btn:after {
    content: var(--icon-arrow);
    font-family: iconfont;
    position: absolute;
    right: calc(22rem / 16);
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    margin: 0;
    font-size: calc(6rem / 16);
}
.filter-dropdown__menu {
    display: none;
    z-index: 10;
    margin-top: calc(-25rem / 16);
    padding: calc(20rem / 16) calc(15rem / 16);
    background: var(--color-light-grey);

    @media screen and (min-width: 768px) {
        display: block;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        background: #fff;
        box-shadow: 0 calc(5rem / 16) calc(40rem / 16) 0 rgba(0, 0, 0, 0.1);
        top: 100%;
        margin-top: calc(14rem / 16);
        left: 50%;
        border-radius: calc(6rem / 16);
        transform: translateX(-50%);
        min-width: calc(412rem / 16);
    }
}
.filter-dropdown__menu--auto {
    @media screen and (min-width: 768px) {
        min-width: 100%;
    }
}
.is-open.filter-dropdown .filter-dropdown__menu {
    display: block;

    @media screen and (min-width: 768px) {
        opacity: 1;
        visibility: visible;
    }
}
.filter-dropdown__menu:before {
    @media screen and (min-width: 768px) {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        -webkit-transform: translateX(-50%) translateY(-100%);
        transform: translateX(-50%) translateY(-100%);
        width: 0;
        height: 0;
        border-color: transparent transparent #fff;
        border-style: solid;
        border-width: 0 .625rem .625rem;
    }
}
@media screen and (min-width: 768px) {
    .filter-dropdown__menu--left {
        left: 0;
        transform: translateX(0);
    }
    .filter-dropdown__menu--left:before {
        left: 15%;
    }
    .filter-dropdown__menu--right {
        left: auto;
        right: 0;
        transform: translateX(0);
    }
    .filter-dropdown__menu--right:before {
        left: auto;
        right: 15%;
    }
}