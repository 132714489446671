.sidebar {
    @media screen and (min-width: 768px) {
        max-width: calc(284rem / 16);
    }
}
.sidebar__content {
    padding: calc(106rem / 16) calc(30rem / 16) calc(25rem / 16);
    border-radius: calc(15rem / 16);
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
    background: #fff;
}
.sidebar__title {
    font-size: calc(20rem / 16);
    letter-spacing: 0;
}
.sidebar__img {
    max-width: calc(158rem / 16);
    margin-bottom: calc(-86rem / 16);
}
.sidebar__icon {
    font-size: calc(20rem / 16);
    margin-right: calc(10rem / 16);
    vertical-align: -.2em;
}