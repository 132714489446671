.img-teaser {
    position: relative;
}
.img-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(40,43,52,0.2) 0%, rgba(35,37,43,0.9) 74.61%, #212328 100%);
    transition: opacity 200ms ease-in-out;
}
.img-teaser:hover:after {
    opacity: .5;
}
.img-teaser__img {
    transition: transform 200ms ease-in-out;
}
.img-teaser:hover .img-teaser__img {
    transform: scale(1.1);
}
.img-teaser__title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    /*transition: letter-spacing 200ms ease-in-out;*/
    letter-spacing: calc(2.5rem / 16);
    font-size: calc(15rem / 16);
    padding: 0 calc(15rem / 16);
}
/*
.img-teaser:hover .img-teaser__title {
    letter-spacing: calc(5rem / 16);
}*/
