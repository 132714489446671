.contact-teaser {
    font-size: calc(14rem / 16);

@media screen and (min-width: 1400px) {
    font-size: calc(15rem / 16);
}
}
.contact-teaser__title {
    font-size: calc(16rem / 16);
    line-height: calc(22/18);

@media screen and (min-width: 1400px) {
    font-size: calc(18rem / 16);
}
}
.contact-teaser__department {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* contact-teaser__new is only temporary so the normal design isn't interrupted */
.contact-teaser__new.contact-teaser {
    /*background: var(--color-white);*/
    padding: calc(20rem / 16);
    font-size: calc(12rem / 16);
    box-shadow: 0 1.25rem 1.25rem 0 rgb(15 15 15 / 3%);
    position: relative;

@media screen and (min-width: 1400px) {
    font-size: calc(13rem / 16);
}
}

.contact-teaser__new.contact-teaser:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-primary);
    opacity: .2;
    z-index: -1;
    transition: all 0.2s ease-in-out;
}

.contact-teaser__new.contact-teaser:hover:before {
    background-color: var(--color-primary-dark);
    opacity: .5;
}

.contact-teaser__new .contact-teaser__title {
    font-size: calc(16rem / 16);
    line-height: calc(22/18);
    color: var(--color-secondary);

@media screen and (min-width: 1400px) {
    font-size: calc(18rem / 16);
}
}

.contact-teaser__badge {
    position: absolute;
    top: calc(100% - 15rem / 16);
    left: 50%;
    width: 100%;
    max-width: 92%;
    overflow-wrap: break-word;
    white-space: normal;
    transform: translateX(-50%);
}