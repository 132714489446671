.datepicker {
    position: relative;
}
.datepicker:after {
    content: var(--icon-calendar);
    font-family: iconfont;
    position: absolute;
    right: calc(20rem / 16);
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-secondary);
}
.datepicker .form-control {
    background: var(--color-light-grey);
}
.datepicker .form-control--light {
    background: transparent;
}