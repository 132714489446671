.article-teaser {
    position: relative;
}
.article-teaser__img {
    transition: transform 200ms ease-in-out;
}
.article-teaser:hover .article-teaser__img {
    transform: rotate(-1deg) scale(.95);
}
.article-teaser__badge {
    position: absolute;
    left: calc(-20rem / 16);
    top: calc(20rem / 16);
}
.article-teaser__title {
    font-size: calc(18rem / 16);
    line-height: calc(28/18);
}
.article-teaser__date {
    font-size: calc(14rem / 16);
    letter-spacing: calc(1.5rem / 16);
}

/* sizes */
.article-teaser--lg {
    position: relative;

    @media screen and (min-width: 768px) {
        overflow: hidden;
    }
}
.article-teaser__content:before {
    content: '';
    position: absolute;
    top: calc(-60rem / 16);
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-primary);
    opacity: .2;
    z-index: -1;

    @media screen and (min-width: 768px) {
        top: 0;
        left: -50em;
    }
}
.article-teaser__content {
    padding: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) 0;
        padding-right: 14%;
    }
}
.article-teaser--lg .article-teaser__title {
    font-size: calc(20rem / 16);
    line-height: calc(28/25);
    letter-spacing: normal;

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
    }
}