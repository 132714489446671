.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: calc(50rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
    }
}
.hero__content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 50vh;

    @media screen and (min-width: 768px) {
        min-height: calc(100vh - calc(90rem / 16));
        justify-content: center;
    }

    @media (orientation: portrait) {
        min-height: 45vh;
    }
}
.hero__bg {
    position: absolute;
    right: 0;
    top: calc(-60rem / 16);
    width: 90%;
    height: 100%;
    background-size: contain;
    background-position: right top;
    background-repeat: no-repeat;
    background-image: var(--image-src-xs) !important;
    @media screen and (min-width: 576px) {
        background-image: var(--image-src) !important;
    }
    @media screen and (min-width: 1200px) {
        width: 70%;
        top: 0;
        background-image: var(--image-src) !important;
        bottom: 0;
        height: calc(100vh - calc(90rem / 16));
    }
}
/*.hero__bg {
    position: absolute;
    right: 0;
    top: calc(-60rem / 16);
    width: 100%;
       height: 50%;
      background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
    background-image: var(--image-src-xs) !important;

    @media screen and (min-width: 768px) {
        width: 52%;
        top: calc(-90rem / 16);
        background-image: var(--image-src) !important;
        height: 100vh;
    }
}*/

.hero__bg.hero__bg--half-full {
    width: 50%;
    max-height: calc(100vh - calc(90rem / 16));
    height: calc(100% - calc(90rem / 16));
    background-size: cover;

    @media screen and (max-width: 767px) {
        position: relative;
        height: calc(270rem / 16);
        width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .hero__subtitle {
        display: inline;
        box-shadow: -1px -1px 0 1px var(--color-page-bg);
        background: var(--color-page-bg);
        font-size: calc(11rem / 16);
        line-height: calc(17/11);
        letter-spacing: calc(3rem / 16);
    }
    .hero__title {
        display: inline;
        box-shadow: -1px -1px 0 1px var(--color-page-bg),0 -2px 0 2px var(--color-page-bg), 0 -1px 0 1px var(--color-page-bg);
        background: var(--color-page-bg);
        line-height: calc(38/30);
    }
}

.hero-sm__img {
    height: calc(250rem / 16);

    @media screen and (min-width: 768px) {
        height: 100%;
        min-height: calc(400rem / 16);
    }

    @media screen and (min-width: 1400px) {
        min-height: calc(517rem / 16);
    }
}
.hero-sm__img__item {
    background-size: cover;
    background-position: center;
    display: block;
    height: 100%;
}
@media screen and (min-width: 768px) {
    .hero-sm {
        padding-bottom: calc(90rem / 16);
    }
    .hero-sm__location {
        font-size: calc(20rem / 16);
    }

    .hero-sm ~ .content-container .content-container__main {
        margin-top: calc(-90rem / 16);
    }
    .hero-sm ~ .content-container .content-container__side {
        margin-top: calc(40rem / 16);
    }
}


.hero-box {
    margin-top: calc(40rem / 16);
    background: #fff;
    box-shadow: 0 calc(15rem / 16) calc(70rem / 16) 0 rgba(40,43,52,0.1);
    padding: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem / 16);
        padding: calc(30rem / 16) calc(20rem / 16);
    }

    @media screen and (min-width: 1400px) {
        margin-top: calc(140rem / 16);
        padding: calc(60rem / 16) calc(20rem / 16);
    }
}

.hero-job-detail {
    margin-top: calc(32rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(48rem / 16);
    }
}

.hero-job-detail__title {
    margin-bottom: 0;
    line-height: 1;
}

.hero-job-detail__subtitle {
    font-size: calc(24rem / 16);
    margin-bottom: 0;
}

.hero-job-detail__buzz-words {
    text-transform: uppercase;
    width: fit-content;
    border-bottom: var(--color-primary) solid calc(2rem / 16);
    margin-bottom: calc(16rem / 16);
    color: var(--color-secondary);
}