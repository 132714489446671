.footer__main {
    padding: calc(30rem / 16) 0;
}
.footer__main__list {
    margin: 0 calc(-18rem / 16);
}
.footer__main__list__item {
    display: inline-block;
    padding: 0 calc(18rem / 16);
}
/*.footer__main__list__item + .footer__main__list__item {

    margin-left: calc(36rem / 16);
}
.footer__main__list--right .footer__main__list__item + .footer__main__list__item {
    margin-left: 0;
    margin-right: calc(36rem / 16);
}*/
.footer__top {
    padding: calc(22rem / 16) 0;
}
.social-list {
    font-size: calc(22rem / 16);
    line-height: .9;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social-list__item {
    padding: 0 calc(18rem / 16);
}
.site-by-valantic {
    max-width: calc(146rem / 16);
    height: auto;
}