.job-detail-info-list__lists {
    position: relative;
    padding: calc(32rem / 16) 0;
}

.job-detail-info-list__lists:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .2;
    z-index: -1;
    background: linear-gradient(90deg, var(--color-primary) 94%, rgba(255,255,255,0) 94%);

    @media screen and (min-width: 768px) {
        background: linear-gradient(90deg, var(--color-primary) 50%, rgba(255,255,255,0) 50%);
    }
}

.job-detail-info-list__list {
    padding-left: calc(20rem / 16);
}

.job-detail-info-list__list-item {
    margin-bottom: calc(4rem / 16);
}

.job-detail-info-list__info-text {
    margin-top: calc(16rem / 16);
}