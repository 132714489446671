.title-block {
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(30rem / 16);
    }
}
.title-block--min-height {
    min-height: calc(90rem / 16);
}

.content-block--sm .title-block {
    margin-bottom: calc(12rem / 16);
}