@media screen and (max-width: 1199px) {
    .nav-toggle {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
        background: #fff;
        padding: calc(4rem / 16);
        border: 0;
        font-size: calc(9rem / 16);
        letter-spacing: calc(.3rem / 16);
        position: relative;
        z-index: 10;
    }
    .is-open.nav-toggle {
        background: var(--color-secondary);
        color: #fff;
    }
    .nav-toggle__line {
        display: block;
        width: calc(23rem / 16);
        height: calc(3rem / 16);
        border-radius: calc(3rem / 16);
        background: currentColor;
        transition: transform 250ms ease-in-out, opacity 200ms ease;
        transform-origin: left center;
        margin: 0 auto;
    }
    .nav-toggle__line + .nav-toggle__line {
        margin-top: calc(5rem / 16);
    }
    .is-open .nav-toggle__line {
        transform: scaleX(.9) rotate(42deg) translateX(0) translateY(-5px);
    }
    .is-open .nav-toggle__line:nth-child(2) {
        transform: scaleX(.9) rotate(-42deg) translateX(0) translateY(5px);
    }
    .is-open .nav-toggle__line:last-child {
        opacity: 0;
    }

    .simple-nav {
        display: none;
    }
    .simple-nav__item.is-open > .simple-nav {
        display: block;
    }

    .simple-nav {
        padding-left: 1.5rem;
        margin-bottom: .5rem;
    }
    .simple-nav__item {
        display: block;
        width: 100%;
    }
    .simple-nav__item-content {
        display: block;
        width: 100%;
        padding: .5rem 0;
    }
    .simple-nav__item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    /* level 0 */
    .simple-nav--level-0 {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--color-page-bg);
        color: var(--color-default);
        margin: 0;
        padding: calc(100rem / 16) calc(54rem / 16) calc(60rem / 16);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        visibility: hidden;
        transform: translateX(-100%);
        transition: 120ms ease;
    }
    .simple-nav--level-0.is-open {
        z-index: 9;
        visibility: visible;
        transform: translateX(0);
    }
    .simple-nav__item--level-0 {
        padding: calc(18rem / 16) 0;
        border-bottom: calc(1rem / 16) solid var(--color-light-grey);
        position: relative;
    }
    .simple-nav__item--level-0:first-child {
        border-top: calc(1rem / 16) solid var(--color-light-grey);
    }
    .is-open.simple-nav__item--level-0 {
        color: #fff;
    }
    .is-open.simple-nav__item--level-0:before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(-54rem / 16);
        right: calc(-54rem / 16);
        bottom: 0;
        background: var(--color-secondary);
        z-index: -1;
    }
    .is-open.simple-nav__item--level-0:after {
        content: '';
        top: 100%;
        left: calc(-54rem / 16);
        right: calc(-54rem / 16);
        position: absolute;
        padding-top: calc(12rem / 16);
        background: var(--color-secondary);
        clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
    .simple-nav__item-content--level-0 {
        font-size: calc(14rem / 16);
        font-family: var(--font-default-bold);
        font-weight: var(--font-default-bold-weight);
    }

    /* Level 1 */
    .simple-nav--level-1 {
        background: var(--color-secondary);
        color: #fff;
        font-size: calc(16rem / 16);
    }
   /* .simple-nav--level-1:before {
        content: '';
        top: 100%;
        left: 0;
        right: 0;
        position: absolute;
        padding-top: calc(12rem / 16);
        background: var(--color-secondary);
        clip-path: polygon(0 0, 50% 100%, 100% 0);
    }*/
}

.simple-nav__collapse-icon {
    @media screen and (min-width: 1200px) {
        display: none;
    }

    @media screen and (max-width: 1199px) {
        display: block;
        font-size: .5em;
        transition: transform 120ms ease-out;
        transform-origin: center center;
        transform: none;
    }
}
.is-open > .simple-nav__item-content > .simple-nav__collapse-icon {
    transform: rotate(180deg);
}