.modal-header {
    padding: calc(30rem / 16) calc(20rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(80rem / 16) calc(90rem / 16) calc(20rem / 16);
    }
}
.modal-body {
    padding: calc(30rem / 16) calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(60rem / 16) calc(90rem / 16);
    }
}
.modal-footer {
    font-size: calc(11rem / 16);
    letter-spacing: calc(1.83rem / 16);
    color: var(--color-text-muted);
    padding: calc(10rem / 16) calc(20rem / 16) calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(90rem / 16) calc(60rem / 16);
    }
}
.modal-header + .modal-body {
    padding-top: 0;
}
.close.modal-header__close {
    position: absolute;
    top: calc(-15rem / 16);
    right: calc(-15rem / 16);
    border-radius: 50%;
    background: var(--color-secondary);
    color: #fff;
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    border: 0;
    opacity: 1;
    margin: 0;
    font-size: calc(12rem / 16);
    transition: background 200ms ease-in-out;
    z-index: 1;

    @media screen and (min-width: 768px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
        top: calc(-20rem / 16);
        right: calc(-20rem / 16);
    }
}
.modal .close.modal-header__close:hover {
    opacity: 1;
    background: var(--color-secondary-dark);
}

.modal-lg .modal-header {
    @media screen and (min-width: 768px) {
        padding: calc(60rem / 16) calc(90rem / 16) calc(30rem / 16);
    }
}
.modal-body__img {
    border-top-left-radius: calc(15rem / 16);
    border-top-right-radius: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: calc(15rem / 16);
    }
}