.benefit-teaser__icon-wrapper {
    margin: 0 auto;
    width: fit-content;
}

.benefit-teaser__text {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
}