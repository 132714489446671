@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1737109419272/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1737109419272/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1737109419272/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-accessible:before { content: "\EA01" }
.icon-addthis:before { content: "\EA02" }
.icon-arrow-circle:before { content: "\EA03" }
.icon-arrow-left:before { content: "\EA04" }
.icon-arrow-right:before { content: "\EA05" }
.icon-arrow:before { content: "\EA06" }
.icon-balcony:before { content: "\EA07" }
.icon-bank:before { content: "\EA08" }
.icon-basement:before { content: "\EA09" }
.icon-basket:before { content: "\EA0A" }
.icon-bus:before { content: "\EA0B" }
.icon-calendar:before { content: "\EA0C" }
.icon-checkmark:before { content: "\EA0D" }
.icon-close:before { content: "\EA0E" }
.icon-construction-brick:before { content: "\EA0F" }
.icon-dino:before { content: "\EA10" }
.icon-doctor:before { content: "\EA11" }
.icon-download:before { content: "\EA12" }
.icon-elements:before { content: "\EA13" }
.icon-expand:before { content: "\EA14" }
.icon-facebook:before { content: "\EA15" }
.icon-filter:before { content: "\EA16" }
.icon-garage:before { content: "\EA17" }
.icon-garden:before { content: "\EA18" }
.icon-heart-filled:before { content: "\EA19" }
.icon-heart-outline:before { content: "\EA1A" }
.icon-helmet:before { content: "\EA1B" }
.icon-house-hands:before { content: "\EA1C" }
.icon-house:before { content: "\EA1D" }
.icon-info:before { content: "\EA1E" }
.icon-instagram:before { content: "\EA1F" }
.icon-keys:before { content: "\EA20" }
.icon-lift:before { content: "\EA21" }
.icon-linkedin:before { content: "\EA22" }
.icon-magnifier:before { content: "\EA23" }
.icon-mail:before { content: "\EA24" }
.icon-map:before { content: "\EA25" }
.icon-minus:before { content: "\EA26" }
.icon-note:before { content: "\EA27" }
.icon-parking-space:before { content: "\EA28" }
.icon-parking:before { content: "\EA29" }
.icon-phone:before { content: "\EA2A" }
.icon-pinterest:before { content: "\EA2B" }
.icon-play:before { content: "\EA2C" }
.icon-plus:before { content: "\EA2D" }
.icon-poi-filled:before { content: "\EA2E" }
.icon-poi-outline:before { content: "\EA2F" }
.icon-printer:before { content: "\EA30" }
.icon-project-plan:before { content: "\EA31" }
.icon-quote:before { content: "\EA32" }
.icon-real-estate:before { content: "\EA33" }
.icon-renovate:before { content: "\EA34" }
.icon-school:before { content: "\EA35" }
.icon-search-house-bold:before { content: "\EA36" }
.icon-search-house:before { content: "\EA37" }
.icon-smartphone:before { content: "\EA38" }
.icon-terrace:before { content: "\EA39" }
.icon-twitter:before { content: "\EA3A" }
.icon-wench:before { content: "\EA3B" }
.icon-whatsapp:before { content: "\EA3C" }
.icon-youtube:before { content: "\EA3D" }


:root {
--icon-accessible: "\EA01";
    --icon-addthis: "\EA02";
    --icon-arrow-circle: "\EA03";
    --icon-arrow-left: "\EA04";
    --icon-arrow-right: "\EA05";
    --icon-arrow: "\EA06";
    --icon-balcony: "\EA07";
    --icon-bank: "\EA08";
    --icon-basement: "\EA09";
    --icon-basket: "\EA0A";
    --icon-bus: "\EA0B";
    --icon-calendar: "\EA0C";
    --icon-checkmark: "\EA0D";
    --icon-close: "\EA0E";
    --icon-construction-brick: "\EA0F";
    --icon-dino: "\EA10";
    --icon-doctor: "\EA11";
    --icon-download: "\EA12";
    --icon-elements: "\EA13";
    --icon-expand: "\EA14";
    --icon-facebook: "\EA15";
    --icon-filter: "\EA16";
    --icon-garage: "\EA17";
    --icon-garden: "\EA18";
    --icon-heart-filled: "\EA19";
    --icon-heart-outline: "\EA1A";
    --icon-helmet: "\EA1B";
    --icon-house-hands: "\EA1C";
    --icon-house: "\EA1D";
    --icon-info: "\EA1E";
    --icon-instagram: "\EA1F";
    --icon-keys: "\EA20";
    --icon-lift: "\EA21";
    --icon-linkedin: "\EA22";
    --icon-magnifier: "\EA23";
    --icon-mail: "\EA24";
    --icon-map: "\EA25";
    --icon-minus: "\EA26";
    --icon-note: "\EA27";
    --icon-parking-space: "\EA28";
    --icon-parking: "\EA29";
    --icon-phone: "\EA2A";
    --icon-pinterest: "\EA2B";
    --icon-play: "\EA2C";
    --icon-plus: "\EA2D";
    --icon-poi-filled: "\EA2E";
    --icon-poi-outline: "\EA2F";
    --icon-printer: "\EA30";
    --icon-project-plan: "\EA31";
    --icon-quote: "\EA32";
    --icon-real-estate: "\EA33";
    --icon-renovate: "\EA34";
    --icon-school: "\EA35";
    --icon-search-house-bold: "\EA36";
    --icon-search-house: "\EA37";
    --icon-smartphone: "\EA38";
    --icon-terrace: "\EA39";
    --icon-twitter: "\EA3A";
    --icon-wench: "\EA3B";
    --icon-whatsapp: "\EA3C";
    --icon-youtube: "\EA3D";
    
}