.scroll-hint {
    position: absolute;
    bottom: calc(10rem / 16);
    right: calc(10rem / 16);
    z-index: 1;
    text-shadow: 0 0 calc(10rem / 16) rgba(0,0,0,0.9);
    color: #fff;
    transform-origin: bottom right;
    transform: rotate(90deg) translateY(100%);
    font-size: calc(14rem / 16);
    display: flex;
    align-items: center;
}
.scroll-hint:before  {
    content:'';
    width: calc(50rem / 16);
    height: calc(2rem / 16);
    background: currentColor;
    box-shadow: 0 0 calc(10rem / 16) rgba(0,0,0,0.9);
}
.scroll-hint__icon  {
    font-size: calc(8rem / 16);
    transform: rotate(-90deg);
}