.text-box__content {
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
    background: #fff;
    padding: calc(35rem / 16) calc(12rem / 16) calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(78rem / 16) calc(35rem / 16) calc(30rem / 16);
    }
}
.text-box__title {
    font-size: calc(18rem / 16);
    line-height: calc(28/25);

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
    }
}
.text-box__img {
    position: relative;
    z-index: 1;
    margin-bottom: calc(-20rem / 16);
    width: calc(85rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-48rem / 16);
        width: calc(162rem / 16);
    }
}