.range-slider__slider {
    margin: calc(18rem / 16) 0;
    height: calc(3rem / 16);
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background: var(--color-dark-grey);
    padding: 0 calc(10rem / 16);
}
.range-slider__slider .noUi-handle {
    width: calc(20rem / 16);
    height: calc(20rem / 16);
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    top: calc(-9rem / 16);
    border: 0;
    box-shadow: 0 calc(5rem / 16) calc(25rem / 16) 0 rgba(40,43,52,0.3);
}
.range-slider__slider .noUi-handle:before,
.range-slider__slider .noUi-handle:after {
    content: none;
}
.range-slider__slider .noUi-handle:focus {
    outline: none;
}
html:not([dir=rtl]) .range-slider__slider.noUi-horizontal .noUi-handle {
    right: calc(-10rem / 16);
}
.range-slider__slider .noUi-connect {
    background: var(--color-secondary);
    box-shadow: none;
    height: calc(3rem / 16);
}

.range-slider__input {
    background: none;
    border: 0;
    color: currentColor;
    cursor: default;
}