html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background: var(--color-page-bg);
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
    .container--no-padding-xs {
        padding: 0;
    }
}
.container.container {
    max-width: calc(1414rem/16);
    width: 100%;
}
.container-md {
    max-width: calc(1110rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container.container-md {
    max-width: calc(1180rem / 16);
}
.container-narrow {
    max-width: calc(846rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container.container-narrow {
    max-width: calc(916rem / 16);
}
.container-xs {
    max-width: calc(638rem / 16);
}

.container.container-half {
    @media screen and (min-width: 768px) {
        max-width: calc(585rem / 16);
        margin-left: auto;
        margin-right: 0;
    }
}