html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(14rem / 16);
    word-break: break-word;

    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}

.font-bold,
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(30rem/16);
    line-height: calc(63/54);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);

    @media screen and (min-width: 768px) {
        font-size: calc(48rem/16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(54rem/16);
    }
}
h2, .h2 {
    font-size: calc(26rem/16);
    line-height: calc(45/40);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);

    @media screen and (min-width: 768px) {
        font-size: calc(34rem/16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(40rem/16);
        line-height: calc(50/40);
    }
}
h3, .h3 {
    font-size: calc(24rem/16);
    line-height: calc(49/32);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    letter-spacing: calc(.2rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(26rem/16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(32rem/16);
    }
}
h4, .h4 {
    font-size: calc(18rem/16);
    line-height: calc(28/18);
    letter-spacing: calc(5rem / 16);
}
h5, .h5 {
    font-size: calc(13rem/16);
    line-height: calc(20/16);
    letter-spacing: calc(2rem / 16);
}
h6, .h6 {
    font-size: 1em;
}

table{
    word-break: initial;
}

.letter-spacing-normal {letter-spacing: normal;}