.img-text-teaser {
    position: relative;
}
.img-text-teaser__image-container {
    position: relative;
    transition: transform 200ms ease-in-out;
    box-shadow: 0 calc(15rem / 16) calc(40rem / 16) 0 rgba(40,43,52,0.15);
    z-index: 2;
}
.img-text-teaser:hover .img-text-teaser__image-container {
    transform: rotate(-1deg) scale(0.96);
}
.img-text-teaser:not(.img-text-teaser--expand) .img-text-teaser__image-container:after {
    content: '';
    bottom: calc(-1rem / 16);
    left: 0;
    right: 0;
    position: absolute;
    padding-top: calc(21rem / 16);
    background: var(--color-primary);
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
}
.img-text-teaser__badge {
    position: absolute;
    left: calc(-15rem / 16);
    top: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        left: calc(-20rem / 16);
        top: calc(32rem / 16);
    }
}
.img-text-teaser__subtitle {
    letter-spacing: calc(1.4rem / 16);
    font-size: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }
}
.img-text-teaser__title {
    font-size: calc(18rem / 16);
    line-height: calc(28/25);

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
    }
}
.img-text-teaser__expand {
    background: var(--color-primary);
    color: var(--color-secondary-light);
    position: absolute;
    right: calc(-1rem / 16);
    left: calc(-1rem / 16);
    bottom: 0;
    font-size: calc(16rem / 16);
    letter-spacing: calc(.6rem / 16);
    padding: calc(5rem / 16) calc(10rem / 16) calc(35rem / 16);
    transform: translateY(100%);
    transition: transform 250ms ease-in-out;
}
.img-text-teaser:hover .img-text-teaser__expand {
    transform: translateY(0);
}
.img-text-teaser__expand:before {
    content: '';
    top: calc(1rem / 16);
    left: 0;
    right: 0;
    position: absolute;
    transform: translateY(-100%);
    padding-top: calc(21rem / 16);
    background: var(--color-primary);
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
}
.img-text-teaser__expand__btn {
    margin-top: -21px;
    opacity: 0;
    transform: translateY(-50%) scale(.5);
    transition: transform 300ms ease-in-out, opacity 250ms ease-in-out;
}
.img-text-teaser:hover .img-text-teaser__expand__btn {
    transform: translateY(-50%) scale(1);
    opacity: 1;
}
.img-text-teaser__expand__icon {
    font-size: calc(26rem / 16);
}

@media screen and (hover: none) {
    .img-text-teaser__expand {
        display: none;
    }
    .img-text-teaser .img-text-teaser__image-container:after {
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        padding-top: calc(21rem / 16);
        background: var(--color-primary);
        clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    }
}