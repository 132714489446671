.alert-bar {
    padding: calc(10rem / 16) 0 calc(6rem / 16);
}
.alert-bar .wysiwyg a {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}

.alert-badge {
    height: calc(65rem / 16);
    width: calc(65rem / 16);
    border-radius: 50%;
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.4);
    z-index: 2;
    position: absolute;
    top: calc(8rem / 16);
    right: calc(8rem / 16);
    padding: calc(10rem / 16);
    line-height: 1.1;
    font-size: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(130rem / 16);
        width: calc(130rem / 16);
        font-size: calc(18rem / 16);
        top: calc(30rem / 16);
        right: calc(30rem / 16);
        line-height: 1.3;
    }
}