.search-bar {
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
    border-radius: calc(45rem / 16);
    position: relative;
}
.search-bar__title {
    border: 0;
    padding: calc(10rem / 16) calc(20rem / 16);
    background: #fff;
    border-bottom-left-radius: calc(45rem / 16);
    border-top-left-radius: calc(45rem / 16);
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        padding: calc(10rem / 16) calc(40rem / 16);
        letter-spacing: calc(3.56rem / 16);
    }
}
.search-bar__title__arrow {
    font-size: calc(5rem / 16);
    margin-left: calc(4rem / 16);
    position: relative;
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        font-size: calc(8rem / 16);
    }
}
.is-open .search-bar__title__arrow {
    transform: scaleY(-1);
}
.search-bar__btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: calc(14rem / 16);
    letter-spacing: calc(2.2rem / 16);
    padding: calc(20rem / 16);
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        padding: calc(24rem / 16) calc(16rem / 16) calc(24rem / 16) calc(12rem / 16);
        font-size: calc(12rem/16);
    }
    @media screen and (min-width: 1200px) {
        padding: calc(30rem / 16) calc(45rem / 16) calc(30rem / 16) calc(35rem / 16);
        font-size: calc(14rem / 16);
    }
}
.search-bar__btn__icon {
    font-size: calc(24rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .search-bar__overlay{
        position: absolute;
        z-index: 3;
        top: calc(100% + calc(14rem / 16));
        background: #fff;
        border-radius: calc(25rem / 16);
        padding: calc(18rem / 16) calc(20rem / 16);
        box-shadow: 0 calc(5rem / 16) calc(40rem / 16) 0 rgba(40,43,52,0.1);
        visibility: hidden;
        opacity: 0;
        transform: translateY(calc(-20rem/16));
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out, visibility 200ms ease-in-out;
    }

    .search-bar.is-open .search-bar__overlay {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
    .search-bar__overlay:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%) translateY(-100%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 calc(10rem / 16) calc(10rem / 16) calc(10rem / 16);
        border-color: transparent transparent #fff transparent;
    }
}