.accordion .card {
    background: transparent;
    border: 0;
    overflow: visible;
    padding-right: calc(10rem / 16);
    margin-bottom: calc(10rem / 16);
}
.accordion .card + .card {
    margin-top: calc(22rem / 16);
}
.accordion .card .card-header {
    background: transparent;
    margin-bottom: 0;
    border: 0;
}
.accordion__header-link {
    position: relative;
    text-align: left;
    display: block;
    width: 100%;
    font-size: calc(16rem / 16);
    line-height: calc(24/16);
    color: var(--color-secondary-light);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    padding: 0 0 calc(8rem / 16) calc(10rem / 16);
    background: none;
    border: 0;
}
.accordion__header-link:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: calc(1rem / 16);
    background: var(--color-light-grey);
}
.accordion__header-link__icon {
    font-size: calc(25rem / 16);
    vertical-align: -.2em;
    margin-right: calc(8rem / 16);
}

.accordion__header-toggle {
    position: relative;
    z-index: 1;
    display: block;
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    background: #fff;
    transition: background 200ms ease-in-out;
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
    margin-bottom: calc(-18rem / 16);
    margin-right: calc(-10rem / 16);
    flex-shrink: 0;
}
.accordion__header-toggle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 200ms ease-in-out;
    color: var(--color-secondary-light);
    font-size: calc(9rem / 16);
}
.accordion__header-link:not(.collapsed) .accordion__header-toggle__icon:before {
    content: var(--icon-minus);
}
.accordion__header-link:focus {
    outline: none;
}
.accordion__body:not(.accordion__body--light){
    position: relative;
    border: calc(1rem / 16) solid var(--color-light-grey);
    border-top: 0;
    padding: calc(20rem / 16) calc(30rem / 16);
    line-height: calc(25/16);

    @media screen and (min-width: 768px) {
        padding: calc(35rem / 16) calc(50rem / 16);
    }
}
.accordion__body:not(.accordion__body--light):before {
    content: attr(data-background-count);
    position: absolute;
    left: calc(-30rem / 16);
    top: calc(-40rem / 16);
    line-height: 1;
    font-size: calc(100rem / 16);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: -1;

    @media screen and (min-width: 768px) {
        font-size: calc(150rem / 16);
        left: calc(-47rem / 16);
        top: calc(-50rem / 16);
    }
}
.show > .accordion__body:before {
    opacity: .15;
}


/* sizes */
.accordion--sm .accordion__header-link {
    font-size: calc(13rem / 16);
    text-transform: uppercase;
    letter-spacing: calc(2rem / 16);
    padding: 0 0 calc(8rem / 16) calc(4rem / 16);
}