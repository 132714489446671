.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(16rem / 16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-secondary-light);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-secondary-light);
    text-decoration: underline;
}

.bg-secondary .wysiwyg a {
    color: var(--color-primary);
}
.bg-secondary .wysiwyg a:hover,
.bg-secondary .wysiwyg a:focus,
.bg-secondary .wysiwyg a:active {
    color: var(--color-primary);
}