.filter-row {
    border-radius: calc(30rem / 16);
    padding: calc(10rem / 16);
    background: #f9f8f4;

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16) calc(15rem / 16);
    }
}
.filter-row__label {
    margin-right: calc(10rem / 16);
}
.filter-row__item {
    flex-shrink: 0;
}
.filter-row__item:not(:last-child) {
    margin-right: calc(6rem / 16);
}