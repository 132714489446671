.download-item {
    border-radius: calc(30rem / 16);
    border: calc(1rem / 16) solid var(--color-light-grey);
    padding: calc(12rem / 16) calc(20rem / 16);
    letter-spacing: calc(1rem / 16);
    transition: background 200ms ease-in-out, box-shadow 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16) calc(30rem / 16);
        letter-spacing: calc(2rem / 16);
    }
}
.download-item:hover {
    background: #fff;
    box-shadow: 0 calc(15rem / 16) calc(50rem / 16) 0 rgba(40,43,52,0.1);
}
.download-item__title {
    font-size: calc(11rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
    }
}
.download-item__size {
    font-size: calc(9rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(11rem / 16);
    }
}
.download-item__icon {
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(25rem / 16);
    }
}

.download-item + .download-item {
    margin-top: calc(15rem / 16);
}